<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header container-fluid">
            <div class="row">
              <div class="col">
                <h2>{{ header }} {{ subheader }}</h2>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <div id="score">
              <div>Your score is</div>
              <div class="highlight">
                {{
                  Math.floor(
                    (score.correctlyAnsweredQuestions / score.allQuestions) *
                      100
                  )
                }}%
              </div>
              <span class="answ-stat">You answered {{ score.correctlyAnsweredQuestions }} out of {{ score.allQuestions }} questions.</span>
            </div>
          </div>

          <div class="modal-footer">
            <button
              id="play-again"
              class="button-footer"
              @click="$emit('reload')"
            >
              Play Again
            </button>
            <button
              id="close-button"
              class="button-footer button-close"
              @click="$emit('close')"
            >
              Close
            </button>
            <button
              id="showansw-button"
              class="button-footer button-showansw"
              @click="$emit('showansw')"
            >
              Show answers
            </button>            
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    header: String,
    subheader: String,
    score: Object,
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90vw;
  max-width: 650px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  text-align: center;
  border: none;
  padding: 0.8rem 1.4rem 0 1.4rem;
}

.modal-header h2 {
  color: rgb(0, 178, 72);
}

.modal-header h3 {
  color: rgb(0, 178, 72);
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin: 0 0;
  /* line-height: 1.3rem; */
  padding: 0.8rem 1.4rem 1.4rem;
}

.modal-body .highlight {
  line-height: 6rem;
}

.modal-body > * {
  margin: 0 0 1.4rem 0;
  padding: 0.25rem 0.5rem;
}

.modal-footer {
  display: flex;
  justify-content: center;
  border: none;
}

/*
 Modal Transition
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.button-footer {
  padding: 1rem 2rem;
  background: linear-gradient(
    210deg,
    /* rgba(187, 0, 47, 0.8), */
    /* rgba(245, 0, 87, 0.6) */
    #50cb14,
    #b6dba3
  );
  border-radius: 7px;
  border: none;
}

.button-footer.button-showansw {
  background: linear-gradient(
    210deg,
    #928aeb,
    #c1bce9
  );

}

.button-footer.button-close {
  background: linear-gradient(
    210deg,
    #dc918b,
    #e4c2c0
  );

}




.anchor-footer {
  color: black;
  text-decoration: none;
  cursor: default;
}

.button-footer:active,
.button-footer:focus {
  outline: none;
}

.button-footer:hover {
  transform: scale(1.02);
}

.highlight {
  /* border-radius: 4px; */
  /* background-color: #d8bd8d; */
  padding: 0.25rem 0.5rem;
  font-size: 5rem;
  color: #7367f0;
  font-weight: bold;
}

/* #score {
  background-color: rgb(210, 200, 200); 
  border-radius: 5px;
  box-shadow: 2px 3px 9px gray; 
} */

#chooseCategory {
  text-align: center;
}



</style>