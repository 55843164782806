<template>
    <div class="questionbody">
    
        <b-card title="Testudy Quiz (MCQs type)" v-if="questionType == 0">
                <quiz-mcq @quiz-completed="handleQuizCompleted" :key="quizKey" ref="qMCQ"/>
                <custom-modal
                v-show="showModal"
                header="Congratulations!"
                subheader="You've completed your quiz!"
                :score="score"
                @reload="updateQuiz"
                @close="closeQuiz"
                @showansw="showAnswers"
                />
        </b-card>
        <b-card title="Testudy Quiz (Open-ended Questions)" v-if="questionType == 1">
                <quiz-showanswer @quiz-completed="handleQuizCompleted" :key="quizKey" ref="qShowanswer"/>
                <custom-modal
                v-show="showModal"
                header="Congratulations!"
                subheader="You've completed your quiz!"
                :score="score"
                @reload="updateQuiz"
                @close="closeQuiz"
                @showansw="showAnswers"
                />
        </b-card>        


    
    <!-- select test type -->
    <b-modal
      ref="modal-form-show-qtype"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <h3>Please select Quiz Type which you want to pass:</h3>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="outline-primary"
        block
        @click="selectQuiz(1)"
      >
        <b>Open-ended Questions</b>
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="outline-primary"
        block
        @click="selectQuiz(0)"
      >
        <b>Multiple Choice Questions (MCQs)</b>
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="outline-secondary"
        block
        @click="closeQuiz"
      >
      Close
      </b-button>      
    </b-modal>

   
    <b-modal
      ref="modal-showanswers"
      scrollable
      title="Answers"
      ok-title="Close"
      ok-only
      centered
      size="lg"
      @close="closeShowAnswers()"
      @cancel="closeShowAnswers()"
      @hide="closeShowAnswers()"      
    >
    <div class="">
      <div v-for="(question, index) in questions" :key="question.id" :class="classQuestion(question.rightAnswer)">
        <h2 class="mt-2"><span v-if="question.rightAnswer" class="emoji-face">🙂</span><span v-else class="emoji-face">☹️</span> {{ index + 1}}. {{ question.question }}</h2>
        <span v-if="question.qcontext"><i>{{ question.qcontext }}</i></span>
        <div v-for="answer in question.answers" :key="answer.key" class="pl-1">
          <div v-if="showVariants || (!showVariants && (answer == question.correct_answer))" class="varlist">
              <span v-html="iconAnsw(answer == question.correct_answer, answer == question.userAnswer)" class="answ-icon"></span>{{ answer }}
          </div>
        </div>
      </div>
    </div>
    </b-modal> 
    

   </div>

</template>
  
  

<script>
import { BCard, BCardText, BLink, BModal, BButton, BRipple } from 'bootstrap-vue'
import CustomModal from "./components/CustomModal.vue";
import QuizMcq from "./components/QuizMCQ.vue";
import Ripple from 'vue-ripple-directive'
import QuizShowanswer from "./components/QuizShowanswer.vue";

export default {
    components: {
    BCard,
    BCardText,
    BLink,
    // BRow,
    // BCol,
    // BFormGroup,
    // BFormInput,
    // BForm,
    // BButton,    
    // BFormTextarea, 
    QuizMcq, 
    QuizShowanswer,
    CustomModal,
    BModal, 
    BButton 
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            quizKey: 0,
            showModal: false,
            score: {
                allQuestions: 0,
                answeredQuestions: 0,
                correctlyAnsweredQuestions: 0,
            },
            questions: '',
            showVariants: true,
            questionType: -1, // 0 - MCQ type, 1 - ShowAnswer type
            // userData: JSON.parse(localStorage.getItem('userData'))      
        }
    },    
    
    // beforeRouteLeave(to, from , next) {
    //     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    //     console.log(this.index)
    //     if (answer) {
    //     next()
    //     } else {
    //     next(false)
    //     }
    // },  

    beforeRouteLeave: function(to, from, next) {
        // Indicate to the SubComponent that we are leaving the route
        let qIsSt = false;

        if (this.questionType == 0) {
            qIsSt = this.$refs.qMCQ.qIsStarted()
        } else if (this.questionType == 1) {
            qIsSt = this.$refs.qShowanswer.qIsStarted()
        }
        
        if (qIsSt) {
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
            if (answer) {
                next()
            } else {
                next(false)
            }            
        } else {
            next()
        }
        // Make sure to always call the next function, otherwise the hook will never be resolved
        // Ref: https://router.vuejs.org/en/advanced/navigation-guards.html
        
    },    
    mounted() {
            this.showModalSelectQType();

            //add adsense
            // if (!this.userData.is_premium) {
            //   document.head.innerHTML += "<script async src=\"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1886774781095399\" crossorigin=\"anonymous\"><\/script>";
            // }    

        },    
    methods: {
        handleQuizCompleted(score, questions, showVariants) {
            this.score = score;
            this.questions = questions;
            this.showVariants = showVariants;
            this.showModal = true;
        },
        updateQuiz() {
            this.questionType = -1;
            this.showModal = false;
            this.quizKey++;
            this.showModalSelectQType();
        }, 
        closeQuiz() {
          this.showModal = false;
          this.$router.push({ name: 'quizzes-page' })
        },
        selectQuiz(qtype) {
            this.questionType = qtype;
            this.$refs['modal-form-show-qtype'].hide();
        },
        showModalSelectQType() {
            this.$refs['modal-form-show-qtype'].show();
        },     
        showAnswers() {
            this.showModal = false;
            this.$refs['modal-showanswers'].show();
        },
        closeShowAnswers() {
            this.showModal = true;
        },
        classQuestion(rightAnswer) {
          if (rightAnswer) {
            return 'right-answer'
          } else {
            return 'wrong-answer'
          }
        },
        // iconAnsw(answerEquolCorrectAnswer, answerEquolUserAnswer){ //✅❌✔️
        //   if (answerEquolCorrectAnswer) {
        //     return '✅';
        //   } else {
        //       return (answerEquolUserAnswer ? '❌' : '✔️');
        //     }
        // }
        iconAnsw(answerEquolCorrectAnswer, answerEquolUserAnswer){ //✅❌✔️✔
          if (answerEquolCorrectAnswer) {
            return '<span class="res-ok">☑</span>';
          } else {
              return (answerEquolUserAnswer ? '<span class="res-wrong">☒</span>' : '☑');
            }
        }        

    }    

}



</script>
    
<style>
    /* * {
    box-sizing: border-box;
    } */
    #app .questionbody{
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    text-align: center;
    /* color: #2c3e50; */
    line-height: 1.6;
    }    
    #app .questionbody h2 {
        color: #7367f0;
        padding: 20px 20px 0px 20px;
    }

    button {
  font-size: 1.1rem;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0.5rem;
  background-color: rgba(100, 100, 100, 0.1);
  border: none;
  border-radius: 0.4rem;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

button:hover:enabled {
  transform: scale(1.02);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
button:focus {
  outline: none;
}
button:active:enabled {
  transform: scale(1.05);
}

#qprogr {
  /* display: flex; */
  max-width: 200px;
  margin: 0 auto;
  

}    

button.rightAnswer {
  animation: flashButton;
  animation-duration: 700ms;
  animation-delay: 200ms;
  animation-iteration-count: 3;
  animation-timing-function: ease-in-out;
  color: black;
  background: linear-gradient(
    210deg,
    rgba(0, 178, 72, 0.25),
    rgba(0, 178, 72, 0.5)
  );
}

button.wrongAnswer {
  color: black;
  background: linear-gradient(
    210deg,
    
    #dc918b,
    #cf493f
  );
}

.right-answer h2 {
  color: #28c76f;
}

.wrong-answer h2 {
  color: #ea5455;
}

.answ-icon {
  padding-right: 3px;
  color: #6e6b7b;
}

.answ-icon .res-ok {
  color: #28c76f;
}

.answ-icon .res-wrong {
  color: #ea5455;
}

.varlist {
  font-size: 1.2em;
  padding: 3px 0 3px 0;
}

h2 .emoji-face {
  font-size: 0.9em;  
  padding-right: 0px;
}

.qcontext {
  font-weight: bold;
  font-style: italic;
  color: #0e9149;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: 90%;
}

</style>
    