<template>
  <div>
    <div class="row">
      <div class="col">
        You have
        <strong>{{ correctAnswers }} correct {{ pluralizeAnswer }}!</strong>
      </div>
    </div>

    <div class="row">
      <div class="col correctAnswers">
          <div>Currently at question {{ index + 1 }} of {{ questions.length }}</div>
          <b-progress :max="questions.length" id="qprogr" class="mt-1 mb-1">
            <b-progress-bar
              variant="success"
              :value="progresCountRight"
              show-value
            />
            <b-progress-bar
              variant="danger"
              :value="progresCountWrong"
              show-value
            />
            <b-progress-bar
              variant="secondary"
              :value="progresCountLeft"
              show-value
            />            

        </b-progress>
          
      </div>
    </div>     

    <div class="row">
      <div class="col questionblock">
        <h2 v-html="loading ? 'Loading...' : currentQuestion.question"></h2>
        <h5 v-if="!loading" class="mb-2">Choose <b>the most exact</b> answer out of given options</h5>
      </div>
    </div>
    

    <div v-if="currQuestionIsLoaded && currentQuestion.qcontext" class="row qcontext" >
      <div class="col">    
        <span>{{ currentQuestion.qcontext }}</span>
      </div>
    </div>    

    <!-- Only first question is displayed -->
    <!-- :key="answer"   -->
    <div class="row butttonlist">
      <div class="col">    
        <form v-if="currentQuestion">
          <button
              v-for="answer in currentQuestion.answers"
              :index="currentQuestion.key"
              :key="answer + currentQuestion.key"  
              v-html="answer"
              @click.prevent="handleClick"
            ></button>
        </form>
      </div>
    </div>


    <div class="row mt-5">
      <div class="col ignore-block">
        <b-button 
          variant="link" 
          size="sm"
          @click.prevent="deactivateQuestionConfirm"
          :disabled="currQuestionIsLoaded || questions.length <= 1"
          v-if="!loading && qauthor.isCurrentUser"
          >
          <span v-if="questions.length > 1">Ignore (delete the incorrectly generated question)</span>
          <span v-else >You can't delete the last question in a test</span>          
        </b-button>
      </div>
    </div>
    
    <div class="row mt-5" v-if="!loading && !qauthor.isCurrentUser">
      <div class="col author-block">
          <span v-if="false"><i>Quiz author: {{qauthor.name}} ({{qauthor.email}})</i></span>
          <span><i>This test was created by Testudy users</i></span>          
      </div>
    </div>    

    <b-modal
      ref="modal-form-deactivate-question"
      centered
      ok-title="Ignore the question"
      cancel-title="Cancel"
      title="Confirmation"
      @ok="deactivateQuestion"
      ok-variant="danger"
    >
      <div class="d-block text-center" v-if="currentQuestion">
        <h3>Do you really want to ignore the question<br>"{{currentQuestion.question}}"?</h3>
      </div>
    </b-modal>

    <b-modal
      ref="modal-form-getpremium"
      centered
      title="Free account limit"
      modal-class="modal-danger"
      hide-footer
      @close="goToProfile()"
      @cancel="goToProfile()"
      @hide="goToProfile()"
    >
      <div class="d-block text-center">
        <p>You can't use not own quizz in free account, please subscribe Premium</p>
      </div>
      <b-button variant="outline-success" class="mt-2" block @click="goToProfile()"><feather-icon icon="AwardIcon" class="mr-50" />Get Premium</b-button>
    </b-modal>   
    

  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BProgressBar, BProgress, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {BProgressBar, BProgress, BButton, BModal },
    
  data() {
    return {
      questions: [],
      loading: true, // loading from back
      index: 0,

      progresCountRight: 0,
      progresCountWrong: 0,
      progresCountLeft: 0,

      currQuestionIsLoaded: true,

      qauthor: {
        isCurrentUser: false,
        email: '',
        name: '',  
        isTestPublic: false
      },
      userData: JSON.parse(localStorage.getItem('userData'))



    };
  },
  computed: {
    currentQuestion() {
      if (this.questions !== []) {
        return this.questions[this.index];
      }
      return null;
    },
    correctAnswers() {
      if (this.questions && this.questions.length > 0) {
        let streakCounter = 0;
        // let cRight = 0;

        this.questions.forEach(function (question) {
          if (!question.rightAnswer) {
            return;
          } else if (question.rightAnswer === true) {
            // cRight++;
            streakCounter++;
          } else {
          }
        });

       
        // this.progresCountRight = cRight;

        return streakCounter;
      } else {
        return "--";
      }
    },
    pluralizeAnswer() {
      // For grammatical correctness
      return this.correctAnswers === 1 ? "Answer" : "Answers";
    },
    quizCompleted() {
      if (this.questions.length === 0) {
        return false;
      }
      /* Check if all questions have been answered */
      let questionsAnswered = 0;
      this.questions.forEach(function (question) {
        question.rightAnswer !== null ? questionsAnswered++ : null;
      });
      return questionsAnswered === this.questions.length;
    },
    score() {
      if (this.questions !== []) {
        return {
          allQuestions: this.questions.length,
          answeredQuestions: this.questions.reduce((count, currentQuestion) => {
            if (currentQuestion.userAnswer) {
              // userAnswer is set when user has answered a question, no matter if right or wrong
              count++;
            }
            return count;
          }, 0),
          correctlyAnsweredQuestions: this.questions.reduce(
            (count, currentQuestion) => {
              if (currentQuestion.rightAnswer) {
                // rightAnswer is true, if user answered correctly
                count++;
              }
              return count;
            },
            0
          ),
        };
      } else {
        return {
          allQuestions: 0,
          answeredQuestions: 0,
          correctlyAnsweredQuestions: 0,
        };
      }
    },
  },
  watch: {
    quizCompleted(completed) {
      completed &&
        setTimeout(() => {
          this.$emit("quiz-completed", this.score, this.questions, true);
        }, 3000);
    },
  },

  

  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })      
    },	    
    async fetchQuestions() {
      


      this.loading = true;
      //let qid = location.search.split('id=')[1]
      let qid = this.$route.params.uid

      useJwt.getQuiz(
        qid, 
        {
          //src_text: this.textQuiz,
        })
        .then(response => {
          let data = response.data
          // console.log('data')
          // console.log(data)          
          // this.items = resData
          // this.totalRows = this.items.length

      // let response = await fetch(
      //   "http://my.testudy.io:8080/apifront/quiz/" + qid + '/'
      // );
      // let index = 0; //To identify single answer
      // let data = await response.json();
      // console.log('data')
      // console.log(data)

          let index = 0; //To identify single answer

          let questions = data.map(
            (question) => {
            let ans = question.answers
            question.answers = []
            for (let i = 0; i <= ans.length - 1; i++) {
              question.answers.push(ans[i].answer)
              if (ans[i].correct) {
                question.correct_answer =ans[i].answer  
              } 
            }

            //shuffle above array
            for (let i = question.answers.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [question.answers[i], question.answers[j]] = [
                question.answers[j],
                question.answers[i],
              ];
            }


            question.rightAnswer = null;
            
            question.key = index;
            index++;
            return question;
          });
          this.questions = questions;
          // this.loading = false;          
          this.progresCountLeft = questions.length;
          this.currQuestionIsLoaded = false;
          this.loadAuthorOfQuiz(qid);
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting quiz",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })    
          }
        }) 
        .finally(()=>{ 
          
        });


      
    },

    handleClick(e) {
      let index = e.target.getAttribute("index");
      let pollutedUserAnswer = e.target.innerHTML; // innerHTML is polluted with decoded HTML entities e.g ' from &#039;
      /* Clear from pollution with ' */
      // console.log(pollutedUserAnswer)
      // deleted_by_my let userAnswer = pollutedUserAnswer.replace(/'/, "&#039;");
      let userAnswer = pollutedUserAnswer;
      //set answer
      this.questions[index].userAnswer = userAnswer;
      /* Set class "clicked" on button with userAnswer -> for CSS Styles; Disable other sibling buttons */
      e.target.classList.add("clicked");
      let allButtons = document.querySelectorAll(`[index="${index}"]`);

      for (let i = 0; i < allButtons.length; i++) {
        if (allButtons[i] === e.target) continue;

        allButtons[i].setAttribute("disabled", "");
      }
   
      this.checkCorrectAnswer(e, index);

    },

    checkCorrectAnswer(e, index) {
      let question = this.questions[index];

      // e.target.classList.add(index);
      
      if (question.userAnswer) {
        this.currQuestionIsLoaded = true;
        if (this.index < this.questions.length - 1) {
          // this.currQuestionIsLoaded = true;
          setTimeout(
            function () {
              // this.questions[index].userAnswer = '';
              this.index += 1;
              this.currQuestionIsLoaded = false;
            }.bind(this),
            5000
          );
        }        
        


        if (question.userAnswer === question.correct_answer) {
          // if (this.questions[index].userAnswer === this.questions[index].correct_answer)  {
          
          /* Set class on Button if user answered right, to celebrate right answer with animation joyfulButton */
          e.target.classList.add("rightAnswer");
          /* Set rightAnswer on question to true, computed property can track a streak out of 20 questions */
          this.questions[index].rightAnswer = true;
          this.progresCountRight++;
        } else {
          
          /* Mark users answer as wrong answer */
          e.target.classList.add("wrongAnswer");
          this.questions[index].rightAnswer = false;
          /* Show right Answer */
          let correctAnswer = this.questions[index].correct_answer;
          let allButtons = document.querySelectorAll(`[index="${index}"]`);
          allButtons.forEach(function (button) {
            if (button.innerHTML === correctAnswer) {
              button.classList.add("showRightAnswer");
            }
          });
          this.progresCountWrong++;
        }
        this.progresCountLeft = this.questions.length - this.progresCountRight - this.progresCountWrong;







      }
    },
    qIsStarted: function() {
      // return ((this.index != 0) && (this.index != (this.questions.length - 1)))
      return ((this.progresCountLeft != this.questions.length) && (this.progresCountLeft != 0))
    },
    deactivateQuestionConfirm(e) {
      this.$refs['modal-form-deactivate-question'].show();
    },

    deactivateQuestion(e) {
      this.currQuestionIsLoaded = true;
      useJwt.deactivateQuestion(
        {
          id: this.currentQuestion.id,
          isactive: false
        })
        .then(response => {
          let data = response.data;
          //delete this.questions[this.index];
          this.questions.splice(this.index, 1);
          this.progresCountLeft -= 1;
          for (let i=0; i < this.questions.length; i++) {
            this.questions[i].key -= 1;   
          }
          // this.index += 1;
          
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error deactiveting the question",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })    
          }
        }) 
        .finally(()=>{ 
          this.currQuestionIsLoaded = false;
        });      
    },

    loadAuthorOfQuiz(qid) {
      useJwt.getAuthorofQuiz(
        qid
        )
        .then(response => {
          let data = response.data;
          if (data) {
            this.qauthor.isCurrentUser = (data[0].id == this.userData.id);
            this.qauthor.email = data[0].email;
            this.qauthor.name = data[0].name;
            this.qauthor.isTestPublic = data[0].is_testpublic;
          }
          if (!this.qauthor.isCurrentUser && !this.userData.is_premium && !this.qauthor.isTestPublic) {
            this.$refs['modal-form-getpremium'].show();
          }

        })
        .catch(error => {
          this.qauthor.isCurrentUser = false;
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting author data",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })    
          }
        }) 
        .finally(()=>{ 
          this.loading = false;          
        });        
    },
    goToProfile() {
        this.$router.push({ name: 'pages-profile' })  
      }           

  },
  mounted() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>

 



@keyframes flashButton {
  0% {
    opacity: 1;
    transform: scale(1.01);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

button.clicked {
  pointer-events: none;
}

button.showRightAnswer {
  animation: flashButton;
  animation-duration: 700ms;
  animation-delay: 200ms;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  color: black;
  background: linear-gradient(
    210deg,
    rgba(0, 178, 72, 0.25),
    rgba(0, 178, 72, 0.5)
  );
} 





</style>
