<template>
    <div>
    
        <div class="row">
        <div class="col">
          You have
          <strong>{{ correctAnswers }} correct {{ pluralizeAnswer }}!</strong>
        </div>
      </div>

      <div class="row">
        <div class="col correctAnswers">
            <div>Currently at question {{ index + 1 }} of {{ questions.length }}</div>
            <b-progress :max="questions.length" id="qprogr" class="mt-1 mb-1">
                <b-progress-bar
                variant="success"
                :value="progresCountRight"
                show-value
                />
                <b-progress-bar
                variant="danger"
                :value="progresCountWrong"
                show-value
                />
                <b-progress-bar
                variant="secondary"
                :value="progresCountLeft"
                show-value
                />            

            </b-progress>
            
         </div>
        </div>  
    
    
        <div class="row">
            <div class="col questionblock">
            <h2 v-html="loading ? 'Loading...' : currentQuestion.question"></h2>
            </div>
        </div>    
    


        <div class="row butttonlist">
            <div class="col">    
                <form v-if="!isCurrQAnswered && !quizCompleted">
                <button
                    @click.prevent="handleClick"
                    class="showAnswerButton"
                    >Show Answer</button>
                </form>
            </div>
        </div>     
        
        <div>
            <div class="col">    
                <div v-if="isCurrQAnswered" class="rightanswer mb-0">
                    <h3>{{ currentQuestion.correct_answer }}</h3>
                </div>
                <div v-if="isCurrQAnswered && currentQuestion.qcontext" class="qcontext mb-0">
                  <i><b>{{ currentQuestion.qcontext }}</b></i>   
                </div>                  

                <div v-if="isCurrQAnswered" class="rightanswertext mb-1 mt-3">
                    <span>Did you get the answer right?</span>   
                </div>

                <form v-if="isCurrQAnswered">
                <button
                    @click.prevent="ClickYesNo(currentQuestion.key, true)"
                    class="rightAnswer"
                    >Yes</button>
                <button
                @click.prevent="ClickYesNo(currentQuestion.key, false)"
                    class="wrongAnswer"
                    >No</button>
                </form>   
                
              
            </div>

        </div> 
        
        <div class="row mt-5">
      <div class="col ignore-block">
        <b-button 
          variant="link" 
          size="sm"
          @click.prevent="deactivateQuestionConfirm"
          :disabled="questions.length <= 1"
          v-if="!loading && qauthor.isCurrentUser"
          >
          <span v-if="questions.length > 1">Ignore (delete the incorrectly generated question)</span>
          <span v-else >You can't delete the last question in a test</span>          
        </b-button>
      </div>
    </div>
    
    <div class="row mt-5" v-if="!loading && !qauthor.isCurrentUser">
      <div class="col author-block">
        <span v-if="false"><i>Quiz author: {{qauthor.name}} ({{qauthor.email}})</i></span>
        <span><i>This test was created by Testudy users</i></span>
      </div>
    </div>    

    <b-modal
      ref="modal-form-deactivate-question"
      centered
      ok-title="Ignore the question"
      cancel-title="Cancel"
      title="Confirmation"
      @ok="deactivateQuestion"
      ok-variant="danger"
    >
      <div class="d-block text-center" v-if="currentQuestion">
        <h3>Do you really want to ignore the question<br>"{{currentQuestion.question}}"?</h3>
      </div>
    </b-modal>        

    <b-modal
      ref="modal-form-getpremium"
      centered
      title="Free account limit"
      modal-class="modal-danger"
      hide-footer
      @close="goToProfile()"
      @cancel="goToProfile()"
      @hide="goToProfile()"
    >
      <div class="d-block text-center">
        <p>You can't use not own quizz in free account, please subscribe Premium</p>
      </div>
      <b-button variant="outline-success" class="mt-2" block @click="goToProfile()"><feather-icon icon="AwardIcon" class="mr-50" />Get Premium</b-button>
    </b-modal>       

    </div>

  </template>


<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BProgressBar, BProgress, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {BProgressBar, BProgress, BButton, BModal},
    
  data() {
    return {
      questions: [],
      loading: true,
      index: 0,

      progresCountRight: 0,
      progresCountWrong: 0,
      progresCountLeft: 0,

      isCurrQAnswered: false,

      qauthor: {
        isCurrentUser: false,
        email: '',
        name: '',
        isTestPublic: false
      },
      userData: JSON.parse(localStorage.getItem('userData'))      

    };
  },
  computed: {
    currentQuestion() {
      if (this.questions !== []) {
        return this.questions[this.index];
      }
      return null;
    },



    correctAnswers() {
      if (this.questions && this.questions.length > 0) {
        let streakCounter = 0;
        
        // let cRight = 0;

        this.questions.forEach(function (question) {
          if (!question.rightAnswer) {
            return;
          } else if (question.rightAnswer === true) {
            // cRight++;
            streakCounter++;
          } else {
          }
        });

       
        // this.progresCountRight = cRight;

        return streakCounter;
      } else {
        return "--";
      }
    },
    pluralizeAnswer() {
      // For grammatical correctness
      return this.correctAnswers === 1 ? "Answer" : "Answers";
    },
    quizCompleted() {
      if (this.questions.length === 0) {
        return false;
      }
      /* Check if all questions have been answered */
      let questionsAnswered = 0;
      this.questions.forEach(function (question) {
        question.rightAnswer !== null ? questionsAnswered++ : null;
      });
      return questionsAnswered === this.questions.length;
    },
    score() {
      if (this.questions !== []) {
        return {
          allQuestions: this.questions.length,
          answeredQuestions: this.questions.reduce((count, currentQuestion) => {
            if (currentQuestion.userAnswer) {
              // userAnswer is set when user has answered a question, no matter if right or wrong
              count++;
            }
            return count;
          }, 0),
          correctlyAnsweredQuestions: this.questions.reduce(
            (count, currentQuestion) => {
              if (currentQuestion.rightAnswer) {
                // rightAnswer is true, if user answered correctly
                count++;
              }
              return count;
            },
            0
          ),
        };
      } else {
        return {
          allQuestions: 0,
          answeredQuestions: 0,
          correctlyAnsweredQuestions: 0,
        };
      }
    },
  },
  watch: {
    quizCompleted(completed) {
        if (completed) {
            this.$emit("quiz-completed", this.score, this.questions, false);
        }
    //   completed &&
    //     setTimeout(() => {
    //       this.$emit("quiz-completed", this.score);
    //     }, 3000);
    },
  },

  

  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })      
    },		    
    async fetchQuestions() {
      this.loading = true;

      let qid = this.$route.params.uid

      useJwt.getQuiz(
        qid, 
        {
          //src_text: this.textQuiz,
        })
        .then(response => {
          let data = response.data


          let index = 0; //To identify single answer

          let questions = data.map(
            (question) => {
            let ans = question.answers
            question.answers = []
            for (let i = 0; i <= ans.length - 1; i++) {
              question.answers.push(ans[i].answer)
              if (ans[i].correct) {
                question.correct_answer =ans[i].answer  
              } 
            }


            question.rightAnswer = null;
            
            question.key = index;
            index++;
            return question;
          });
          this.questions = questions;
          //this.loading = false;          
          this.progresCountLeft = questions.length;
          this.loadAuthorOfQuiz(qid);
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {  
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting quiz",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })      
          }    
        }) 
        .finally(()=>{ 
          
        });


      
    },

    handleClick(e) {

    //   let index = e.target.getAttribute("index");
    //   let pollutedUserAnswer = e.target.innerHTML; // innerHTML is polluted with decoded HTML entities e.g ' from &#039;
    //   /* Clear from pollution with ' */
    //   // console.log(pollutedUserAnswer)
    //   let userAnswer = pollutedUserAnswer.replace(/'/, "&#039;");
    //   //set answer
    //   this.questions[index].userAnswer = userAnswer;
    //   /* Set class "clicked" on button with userAnswer -> for CSS Styles; Disable other sibling buttons */
    //   e.target.classList.add("clicked");
    //   let allButtons = document.querySelectorAll(`[index="${index}"]`);

    //   for (let i = 0; i < allButtons.length; i++) {
    //     if (allButtons[i] === e.target) continue;

    //     allButtons[i].setAttribute("disabled", "");
    //   }
   
    //   this.checkCorrectAnswer(e, index);
    this.isCurrQAnswered = true

    },


    ClickYesNo(cQKey, clickedYes){
        let index = cQKey;
        if (this.questions && this.questions.length > 0) {
            if (clickedYes) {
                this.questions[index].rightAnswer = true;
                this.progresCountRight++;    
            } else {
                this.questions[index].rightAnswer = false;
                this.progresCountWrong++;
            }
            this.progresCountLeft = this.questions.length - this.progresCountRight - this.progresCountWrong;
        } 
        this.isCurrQAnswered = false;
        if (this.index < this.questions.length - 1) {
            this.index += 1;
        }
    },

    // checkCorrectAnswer(e, index) {
    //   let question = this.questions[index];
     
    //   if (question.userAnswer) {

    //     if (this.index < this.questions.length - 1) {
          
    //       setTimeout(
    //         function () {
    //           // this.questions[index].userAnswer = '';
    //           this.index += 1;
    //         }.bind(this),
    //         3000
    //       );
    //     }        
        

    //     if (question.userAnswer === question.correct_answer) {
    //       e.target.classList.add("rightAnswer");

    //       this.questions[index].rightAnswer = true;
    //       this.progresCountRight++;
    //     } else {
          
    //       /* Mark users answer as wrong answer */
    //       e.target.classList.add("wrongAnswer");
    //       this.questions[index].rightAnswer = false;
    //       /* Show right Answer */
    //       let correctAnswer = this.questions[index].correct_answer;
    //       let allButtons = document.querySelectorAll(`[index="${index}"]`);
    //       allButtons.forEach(function (button) {
    //         if (button.innerHTML === correctAnswer) {
    //           button.classList.add("showRightAnswer");
    //         }
    //       });
    //       this.progresCountWrong++;
    //     }
    //     this.progresCountLeft = this.questions.length - this.progresCountRight - this.progresCountWrong;







    //   }
    // },
    qIsStarted: function() {
      // return ((this.index != 0) && (this.index != (this.questions.length - 1)))
      return ((this.progresCountLeft != this.questions.length) && (this.progresCountLeft != 0))
    },

    deactivateQuestionConfirm(e) {
      this.$refs['modal-form-deactivate-question'].show();
    },

    deactivateQuestion(e) {
      useJwt.deactivateQuestion(
        {
          id: this.currentQuestion.id,
          isactive: false
        })
        .then(response => {
          let data = response.data;
          //delete this.questions[this.index];
          this.questions.splice(this.index, 1);
          this.progresCountLeft -= 1;
          for (let i=0; i < this.questions.length; i++) {
            this.questions[i].key -= 1;   
          }
          // this.index += 1;
          
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error deactiveting the question",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })    
          }
        }) 
        .finally(()=>{ 
          
        });      
    },

    loadAuthorOfQuiz(qid) {
      useJwt.getAuthorofQuiz(
        qid
        )
        .then(response => {
          let data = response.data;
          if (data) {
            this.qauthor.isCurrentUser = (data[0].id == this.userData.id);
            this.qauthor.email = data[0].email;
            this.qauthor.name = data[0].name;
            this.qauthor.isTestPublic = data[0].is_testpublic;
          }
          if (!this.qauthor.isCurrentUser && !this.userData.is_premium && !this.qauthor.isTestPublic) {
            this.$refs['modal-form-getpremium'].show();
          }          
        })
        .catch(error => {
          this.qauthor.isCurrentUser = false;
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting author data",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.email,
                },
              })    
          }
        }) 
        .finally(()=>{ 
          this.loading = false;          
        });        
    },        
    goToProfile() {
        this.$router.push({ name: 'pages-profile' })  
      }        

  },
  mounted() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>

.rightanswer h3 {
    font-weight: bold;
}

button.rightAnswer, button.wrongAnswer {
    padding: 1rem 2rem 1rem 2rem;
}

button.showAnswerButton {
    padding: 1rem;
}


</style>